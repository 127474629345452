import { Box, Grid, Group, Loader, Text } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { IconGripVertical, IconSortDescending2 } from '@tabler/icons-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import { Screen } from '@/components/Screen'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { GetContentModulesResponse } from '@/services/api/api.types'
import { ContentModuleStatus } from '@/services/api/api.types'

export function PriorityScreen() {
  const { api, authenticationStore } = useGlobalState()
  const queryClient = useQueryClient()

  // Fetch and sort modules by priority
  const modulesQuery = useQuery({
    queryKey: ['modules'],
    queryFn: () => api.getContentModules(),
    select: data => {
      const filtered =
        data?.filter(
          module =>
            // Filter out removed modules and show only editable modules
            module.status !== ContentModuleStatus.Removed &&
            (module.editable !== false ||
              (module.visibleToMsk && !module.allowedForAllSponsors && authenticationStore.user?.isSponsorAdmin)),
        ) ?? []
      return [...filtered].sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
    },
  })

  // Local state for drag and drop reordering
  const [modules, handlers] = useListState(modulesQuery.data ?? [])

  // Keep local state in sync with server data
  React.useEffect(() => {
    if (modulesQuery.data) {
      handlers.setState(modulesQuery.data)
    }
  }, [modulesQuery.data])

  // Mutation to update priorities on the server
  const updatePriorityMutation = useMutation({
    mutationFn: (modules: GetContentModulesResponse) => {
      return Promise.all(
        modules.map((module, index, array) => {
          if (!module?.taskId) return Promise.resolve()
          // Calculate priority (100 to 0) based on position
          const priority = Math.round(100 * (1 - index / (array.length - 1)))
          return api.updateContentModule({
            task_id: module.taskId,
            priority,
          })
        }),
      )
    },
    onSuccess: (_, variables) => {
      // Update cache without triggering refetch
      queryClient.setQueryData(['modules'], variables)
      queryClient.invalidateQueries({
        queryKey: ['modules'],
        refetchType: 'none',
      })
    },
    onError: () => {
      // On error, refetch to reset to server state
      queryClient.invalidateQueries({ queryKey: ['modules'] })
    },
  })

  // Loading and error states
  if (modulesQuery.isError) {
    console.error('Error loading modules:', modulesQuery.error)
    return (
      <Screen title="Module Prioritization" icon={IconSortDescending2}>
        <Text c="red">Error loading modules</Text>
      </Screen>
    )
  }

  if (modulesQuery.isLoading) {
    return (
      <Screen title="Module Prioritization" icon={IconSortDescending2}>
        <Group justify="center" p="xl">
          <Loader />
        </Group>
      </Screen>
    )
  }

  if (!modules.length) {
    return (
      <Screen
        title="Module Prioritization"
        description="Drag your modules to rank them from highest priority to lowest"
        icon={IconSortDescending2}>
        <Text c="dimmed" ta="center" p="xl">
          No active modules found
        </Text>
      </Screen>
    )
  }

  // Render draggable module list
  const items = modules.map((item, index) => (
    <Grid key={item.taskId ?? index} align="center" gutter={0}>
      <Grid.Col span={0.6}>
        {/* Number label that highlights on drag over */}
        <Text
          size="lg"
          fw={500}
          c="dimmed"
          ta="left"
          px="sm"
          ref={(el: HTMLParagraphElement | null) => {
            if (el) el.dataset.index = index.toString()
          }}>
          {index + 1}
        </Text>
      </Grid.Col>
      <Grid.Col span={8}>
        <Group
          p="md"
          mb="xs"
          bg="white"
          w="100%"
          style={{
            borderRadius: 8,
            border: '1px solid #eee',
            cursor: 'grab',
          }}
          draggable
          onDragStart={e => {
            e.currentTarget.style.opacity = '0.5'
            e.dataTransfer?.setData('text/plain', index.toString())
          }}
          onDragEnd={e => {
            e.currentTarget.style.opacity = '1'
            // Reset any highlighted numbers
            document.querySelectorAll(`[data-index="${index}"]`).forEach(el => {
              if (el instanceof HTMLElement) el.style.color = ''
            })
          }}
          onDragOver={e => {
            e.preventDefault()
            // Highlight the number when dragging over
            const numberEl = e.currentTarget.closest('.mantine-Grid-root')?.querySelector(`[data-index="${index}"]`)
            if (numberEl instanceof HTMLElement) numberEl.style.color = 'var(--mantine-color-blue-filled)'
          }}
          onDragLeave={e => {
            // Reset number highlight
            const numberEl = e.currentTarget.closest('.mantine-Grid-root')?.querySelector(`[data-index="${index}"]`)
            if (numberEl instanceof HTMLElement) numberEl.style.color = ''
          }}
          onDrop={e => {
            e.preventDefault()
            // Reset number highlight
            const numberEl = e.currentTarget.closest('.mantine-Grid-root')?.querySelector(`[data-index="${index}"]`)
            if (numberEl instanceof HTMLElement) numberEl.style.color = ''

            const fromIndex = Number(e.dataTransfer?.getData('text/plain'))
            const toIndex = index

            // Update local state immediately for smooth UX
            handlers.setState(currentModules => {
              const newModules = [...currentModules]
              const [movedItem] = newModules.splice(fromIndex, 1)
              newModules.splice(toIndex, 0, movedItem)
              return newModules
            })

            // Send update to server
            const updatedModules = [...modules]
            const [movedItem] = updatedModules.splice(fromIndex, 1)
            updatedModules.splice(toIndex, 0, movedItem)
            updatePriorityMutation.mutate(updatedModules)
          }}>
          <IconGripVertical size={18} style={{ cursor: 'grab' }} />
          <Box>{item.name ?? 'Unnamed Module'}</Box>
        </Group>
      </Grid.Col>
    </Grid>
  ))

  return (
    <Screen
      title="Module Prioritization"
      description={<Text ta="left">Drag the modules to rank them from highest priority to lowest</Text>}
      icon={IconSortDescending2}>
      <Box>{items}</Box>
    </Screen>
  )
}
