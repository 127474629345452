import { Avatar, Group, MultiSelect, Select, Stack, Text } from '@mantine/core'
import { Grid } from '@mantine/core'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

// import { GetESKsResponse } from "@/services/api/api.types"
// import { ModuleFormSchema } from "@/utils/contentModuleForm"
// import { modals } from "@mantine/modals"
// import type { z } from 'zod'
import avatars from '@/assets/avatars'
import { theme } from '@/configs/theme'
import { MSK_TOPIC } from '@/constants/contentModules'
import { useGlobalState } from '@/hooks/useGlobalState'
import * as classes from '@/styles/ContentModuleFormScreen.css'
import { getRequiredFieldDescriptionProps } from '@/utils/forms'

import type { CMFormSection } from './ContentModuleFormScreen'

export const TopicSettingsSection = observer(function TopicSettingsSection(props: CMFormSection) {
  const { authenticationStore } = useGlobalState()
  const { user } = authenticationStore
  const topic = props.moduleForm.getValues().categories[0]
  const topicOptions = useMemo(() => {
    let options = topicOptionsMapper(props.topicsQuery.data ?? [])
    if (user?.isSponsorAdmin && props.moduleForm.values.visibleToMsk) {
      options = [
        {
          value: MSK_TOPIC,
          label: MSK_TOPIC,
        },
      ]
    }

    return options
  }, [props.topicsQuery.data, props.moduleForm.values.visibleToMsk])
  // const eskOptions = useMemo(() => eskOptionsMapper(props.esksQuery.data ?? []), [props.esksQuery.data])

  const subTopics = useMemo(() => {
    const t = props.topicsQuery.data?.find(t => t.displayName === topic)
    if (!t) return []

    return topicOptionsMapper(t.subtopics)
  }, [topic])

  // const eskForTopic = useMemo(() => {
  //   const t = props.topicsQuery.data?.find(t => t.displayName === topic)
  //   if (!t) return null

  //   const esk = props.esksQuery.data?.find(e => e.topicId === t.id)
  //   return esk?.eskId ?? null
  // }, [props.esksQuery.data, props.topicsQuery.data, topic])

  const eskAvatar = useMemo(() => {
    if (!props.esksQuery.data) return avatars.placeholder

    const esk = props.esksQuery.data.find(e => e.eskId === props.moduleForm.getValues().selectEsk)

    return esk ? avatars[esk.avatarId] : avatars.placeholder
  }, [props.moduleForm.getValues().selectEsk, props.esksQuery.data])

  const hasSubTopics = subTopics.length > 0
  // const hasCategories = props.moduleForm.getValues().categories.length > 0

  return (
    <Grid gutter="xxxl">
      <Grid.Col span={4}>
        <Select
          label="Topic"
          withAsterisk
          styles={{
            label: {
              fontSize: '15px',
            },
          }}
          clearable={!props.moduleForm.values.visibleToMsk}
          data={topicOptions}
          {...props.moduleForm.getInputProps('categories')}
          value={props.moduleForm.values.visibleToMsk ? MSK_TOPIC : (topic ?? null)}
          {...getRequiredFieldDescriptionProps(
            props.moduleForm,
            'categories',
            'Select the topic your module is about.',
          )}
          onChange={v => {
            props.moduleForm.setFieldValue('mappings', [])

            const topic = props.topicsQuery.data?.find(t => t.displayName === v)

            if (!v || !topic) {
              if (props.moduleForm.values.visibleToMsk) {
                props.moduleForm.setFieldValue('categories', [MSK_TOPIC])
              } else {
                props.moduleForm.setFieldValue('categories', [])
                props.moduleForm.setFieldValue('selectEsk', '')
              }
            } else {
              props.moduleForm.setFieldValue('categories', [topic.displayName])

              const esk = props.esksQuery.data?.find(e => e.topicId === topic.id)

              if (esk) {
                props.moduleForm.setFieldValue('selectEsk', esk.eskId)
              }
            }
          }}
        />
      </Grid.Col>

      {!props.moduleForm.values.visibleToMsk && (
        <>
          <Grid.Col span={4}>
            <MultiSelect
              label="Sub-Topics"
              withAsterisk
              styles={{
                label: {
                  fontSize: '15px',
                },
              }}
              data={subTopics}
              disabled={!hasSubTopics}
              {...props.moduleForm.getInputProps('mappings')}
              {...getRequiredFieldDescriptionProps(
                props.moduleForm,
                'mappings',
                'Select sub-topics within selected topic.',
              )}
            />
          </Grid.Col>

          <Grid.Col span={4}>
            <Group gap="lg">
              <Avatar
                variant="transparent"
                className={theme.cx(classes.eskAvatarBase, !!eskAvatar && classes.eskAvatar)}
                radius="sm"
                size="lg"
                src={eskAvatar}
              />

              <Stack gap={4} flex={1} h={120}>
                <Text style={{ fontSize: '15px' }}>Expert Sidekick</Text>
                <Text size="lg" fw={500}>
                  {props.esksQuery.data?.find(e => e.eskId === props.moduleForm.getValues().selectEsk)?.name ?? ''}
                </Text>
              </Stack>

              {/* <Select
                flex={1}
                label="Expert Sidekick"
                data={eskOptions}
                disabled={true}
                {...props.moduleForm.getInputProps('selectEsk')}
                {...getRequiredFieldDescriptionProps(
                  props.moduleForm,
                  'selectEsk',
                  '', // 'Select an Expert Sidekick to present this module.'
                )}
                onChange={e => {
                  if (eskForTopic && e !== eskForTopic) {
                    modals.openConfirmModal({
                      title: 'Are you sure?',
                      centered: true,
                      children: (
                        <Text size="sm">{`The area of expertise for the Expert Sidekick you have chosen is not "${props.moduleForm.getValues().categories?.[0]}". Would you like to proceed?`}</Text>
                      ),
                      labels: { confirm: 'Yes', cancel: 'Cancel' },
                      groupProps: { mt: 'xl' },
                      onConfirm: () => {
                        props.moduleForm.setFieldValue('selectEsk', e as z.infer<ModuleFormSchema>['selectEsk'])
                      },
                    })
                  } else {
                    props.moduleForm.setFieldValue('selectEsk', e as z.infer<ModuleFormSchema>['selectEsk'])
                  }
                }}
              /> */}
            </Group>
          </Grid.Col>
        </>
      )}
    </Grid>
  )
})

// function eskOptionsMapper(data: GetESKsResponse) {
//   return data.map(esk => ({ value: esk.eskId, label: esk.name ?? `Avatar ${esk.eskId}` }))
// }

function topicOptionsMapper(data: { displayName: string }[]) {
  return data.map(c => ({ value: c.displayName, label: c.displayName })).filter(c => !!c.label)
}
