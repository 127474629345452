import { Box, Grid, Image, Indicator, Text, Textarea, TextInput } from '@mantine/core'
import { IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { IconTrash } from '@tabler/icons-react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { AIActionButton } from '@/components/AIActionButton'
import { AIInputWrapper } from '@/components/AIInputWrapper'
import { FileUpload } from '@/components/FileUpload'
import { InputLabel } from '@/components/InputLabel'
import { useFileUpload } from '@/hooks/useFileUpload'
import { useGlobalState } from '@/hooks/useGlobalState'
import { useMockSMESKChatIntegration } from '@/hooks/useMockSMESKIntegration'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import * as commonClasses from '@/styles/componentCommons.css'
import * as classes from '@/styles/ContentModuleFormScreen.css'
import type { ModuleFormSchema } from '@/utils/contentModuleForm'
import { getRequiredFieldDescriptionProps } from '@/utils/forms'

export const RequiredSettingsSection = observer(function RequiredSettingsSection(props: CMFormSection) {
  const { configStore } = useGlobalState()
  const { askSMESKForField, askSMESKForDemographic, askSMESKForStepObjectives } =
    useMockSMESKChatIntegration<ModuleFormSchema>(props)
  const [hasSteps, setHasSteps] = useState(false)

  const { uploading: titleImageUploading, upload: uploadTitleImage } = useFileUpload({
    onPrepareError: () =>
      props.moduleForm.setFieldError('titleImage', 'Something went wrong. Please try again or contact support.'),
    onUploadError: () =>
      props.moduleForm.setFieldError('titleImage', 'Upload failed. Please try again or contact support.'),
    onUploadSuccess: () => props.moduleForm.setFieldError('titleImage', undefined),
  })

  useEffect(() => {
    setHasSteps(!!props.stepsQuery.data?.length)
  }, [props.stepsQuery.data])

  return (
    <Grid gutter="xl">
      <Grid.Col span={12}>
        <AIInputWrapper
          disabled={!hasSteps || props.moduleForm.isDirty('name')}
          generating={props.generateMetadataMutation.isPending}
          value={props.metadataForm.getValues().module?.name}
          clearValue={() => props.metadataForm.setFieldValue('module.name', null)}>
          <TextInput
            withAsterisk
            label="Title"
            disabled={!hasSteps}
            placeholder={!hasSteps ? 'Please add content above before editing this field' : undefined}
            rightSection={
              <AIActionButton
                variant="action-button"
                onClick={() =>
                  askSMESKForField({
                    type: 'cm',
                    userChatFieldLabel: 'Title',
                    formField: 'name',
                    apiParamField: 'name',
                    apiResponseField: 'name',
                    actions: ['accept', 'regenerate'],
                  })
                }
              />
            }
            {...props.moduleForm.getInputProps('name')}
            {...getRequiredFieldDescriptionProps(props.moduleForm, 'name', 'Name of your module.')}
          />
        </AIInputWrapper>
      </Grid.Col>

      <Grid.Col span={6}>
        <AIInputWrapper
          disabled={!hasSteps || props.moduleForm.isDirty('shortDescription')}
          generating={props.generateMetadataMutation.isPending}
          value={props.metadataForm.getValues().module?.shortDescription}
          clearValue={() => props.metadataForm.setFieldValue('module.shortDescription', null)}>
          <Textarea
            withAsterisk
            label="Module Description"
            autosize
            resize="vertical"
            disabled={!hasSteps}
            placeholder={!hasSteps ? 'Please add content above before editing this field' : undefined}
            classNames={{ input: classes.mskIntroductionInput }}
            rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
            rightSection={
              <AIActionButton
                variant="action-button"
                onClick={() =>
                  askSMESKForField({
                    type: 'cm',
                    formField: 'shortDescription',
                    userChatFieldLabel: 'Module Description',
                    apiParamField: 'short_description',
                    apiResponseField: 'shortDescription',
                    actions: ['accept', 'regenerate'],
                  })
                }
              />
            }
            {...props.moduleForm.getInputProps('shortDescription')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'shortDescription',
              'The Personal Sidekick will use this to describe the module to users before they transfer to the Expert Sidekick.',
            )}
          />
        </AIInputWrapper>
      </Grid.Col>

      <Grid.Col span={6}>
        <AIInputWrapper
          disabled={!hasSteps || props.moduleForm.isDirty('demographic')}
          generating={props.generateDemographicMutation.isPending}
          value={props.metadataForm.getValues().demographic}
          clearValue={() => props.metadataForm.setFieldValue('demographic', undefined)}>
          <Textarea
            withAsterisk
            label="Target Audience"
            autosize
            resize="vertical"
            disabled={!hasSteps}
            placeholder={!hasSteps ? 'Please add content above before editing this field' : undefined}
            classNames={{ input: classes.mskIntroductionInput }}
            rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
            rightSection={
              <AIActionButton
                variant="action-button"
                onClick={() => askSMESKForDemographic({ actions: ['accept', 'regenerate'] })}
              />
            }
            {...props.moduleForm.getInputProps('demographic')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'demographic',
              'Who is the target audience for your content?',
            )}
          />
        </AIInputWrapper>
      </Grid.Col>

      {/* TODO: This is a temporary hack, it's pretending to be the CM level objective when it's really objectives for the first section */}
      <Grid.Col span={6}>
        <AIInputWrapper
          disabled={!hasSteps || props.moduleForm.isDirty('stepObjectives')}
          generating={props.generateStepObjectivesMutation.isPending}
          value={props.metadataForm.getValues().stepObjectives}
          clearValue={() => props.metadataForm.setFieldValue('stepObjectives', undefined)}>
          <Textarea
            label="Module Objectives"
            autosize
            disabled={!hasSteps}
            placeholder={!hasSteps ? 'Please add content above before editing this field' : undefined}
            resize="vertical"
            minRows={8}
            {...props.moduleForm.getInputProps('stepObjectives')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'stepObjectives',
              'The Sidekick will use these objectives to proactively guide the user through the content.',
            )}
            classNames={{ input: classes.mskIntroductionInput }}
            rightSectionProps={{ className: commonClasses.textareaRightSectionWithAIActionButton }}
            rightSection={
              <AIActionButton
                disabled={!hasSteps}
                variant="action-button"
                onClick={() => askSMESKForStepObjectives({ actions: ['accept', 'regenerate'] })}
              />
            }
          />
        </AIInputWrapper>
      </Grid.Col>

      <Grid.Col span={6}>
        {props.moduleForm.getValues().titleImage ? (
          <Box>
            <InputLabel label="Module Intro Image" />

            <Indicator
              color="red"
              size={36}
              withBorder
              label={<IconTrash size={22} className={classes.tableRowIcon} stroke={1.5} />}
              styles={{ indicator: { cursor: 'pointer' } }}
              onClick={() => props.moduleForm.setFieldValue('titleImage', null)}>
              <Image
                radius="md"
                src={`${configStore.serverBaseUrl}/files/${props.moduleForm.getValues().titleImage}`}
                h="auto"
                w="100%"
              />
            </Indicator>

            <Text c="dimmed" className={classes.inputDescription}>
              This image will be displayed when the module is first introduced.
            </Text>
          </Box>
        ) : (
          <FileUpload
            label="Module Intro Image"
            activateOnClick={false}
            loading={titleImageUploading}
            maxFiles={1}
            error={props.moduleForm.getInputProps('titleImage').error}
            titles={{ idle: 'Upload Image' }}
            accept={IMAGE_MIME_TYPE}
            primaryActionLabel="Select File"
            onDrop={async files => {
              const file = files[0]

              const uploadResponse = await uploadTitleImage(file)

              if (!uploadResponse) return

              props.moduleForm.setFieldValue('titleImage', uploadResponse.fileName)
            }}
            description="This image will be displayed when the module is first introduced."
            hint="Drag and drop image file here or click below to browse. File should be in .jpg, .png, or .gif formats."
          />
        )}
      </Grid.Col>

      {/* <Grid.Col span={6}>
        <Stack gap="xxxl">
          <DatePickerInput
            clearable
            disabled
            label={<ComingSoon prefix="Expiration Date " />}
            leftSection={<IconCalendar size={24} stroke={1.5} />}
            leftSectionPointerEvents="none"
            {...props.moduleForm.getInputProps('expirationDate')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'expirationDate',
              'Module will be auto-archived on this date.',
            )}
          />

          <DatePickerInput
            clearable
            disabled
            label={<ComingSoon prefix="Due Date " />}
            leftSection={<IconCalendar size={24} stroke={1.5} />}
            leftSectionPointerEvents="none"
            {...props.moduleForm.getInputProps('dueDate')}
            {...getRequiredFieldDescriptionProps(
              props.moduleForm,
              'dueDate',
              'Deadline for users to complete the module.',
            )}
          />
        </Stack>
      </Grid.Col> */}

      {/* <Grid.Col span={6}>
        <InputLabel
          label={<ComingSoon prefix="Which segment of your population is this content for? " />}
          withDivider
          mb={theme.rem(12)}
        />

        <Stack gap="xl">
          <Checkbox.Group label="Age Groups">
            <Grid gutter="sm">
              <Grid.Col span={3}>
                <Checkbox label="18-24" value="18-24" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Checkbox label="25-35" value="25-35" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Checkbox label="36-50" value="36-50" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Checkbox label="51+" value="51+" />
              </Grid.Col>
            </Grid>
          </Checkbox.Group>

          <Radio.Group label="Gender">
            <Grid gutter="sm">
              <Grid.Col span={3}>
                <Radio label="Men" value="Men" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Radio label="Women" value="Women" />
              </Grid.Col>

              <Grid.Col span={3}>
                <Radio label="All" value="All" />
              </Grid.Col>
            </Grid>
          </Radio.Group>
        </Stack>
      </Grid.Col> */}

      {/* <Grid.Col span={6}>
        <InputLabel
          label={
            <ComingSoon prefix="Which media can the Expert Sidekick use in personalizing content delivery to member preferences? " />
          }
          withDivider
          mb={theme.rem(12)}
        />

        <Checkbox.Group>
          <Stack gap="lg">
            <Checkbox label="Audio" value="Audio" />
            <Checkbox label="Video" value="Video" />
            <Checkbox label="Text" value="Text" />
          </Stack>
        </Checkbox.Group>
      </Grid.Col> */}
    </Grid>
  )
})
