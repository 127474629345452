import { AppShell, Burger, Button, Divider, Group, Image, Stack, Text } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import {
  IconChartColumn,
  IconHelpCircle,
  IconHexagons,
  IconSortDescending2,
  IconSortDescending2Filled,
  IconUsers,
} from '@tabler/icons-react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'
import type { PropsWithChildren } from 'react'
import { useEffect, useMemo } from 'react'
import { Link } from 'wouter'

import logo from '@/assets/images/tmp-logo.png'
import { Chat } from '@/components/Chat'
import { GlobalLoadingOverlay } from '@/components/GlobalLoadingOverlay'
import { Navbar } from '@/components/Navbar'
import type { LinksGroup } from '@/components/NavbarLinksGroup'
import { NavbarLinksGroup } from '@/components/NavbarLinksGroup'
import { SearchModal } from '@/components/SearchModal'
import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import * as classes from '@/styles/Navbar.css'
import { getRoutePath } from '@/utils/navigation'

type DashboardLayoutProps = PropsWithChildren

export const DashboardLayout = observer(function DashboardLayout(props: DashboardLayoutProps) {
  const { authenticationStore, chatStore } = useGlobalState()

  const { user } = authenticationStore
  const sponsorName = user?.sponsorName

  const isSponsorAdmin = (): boolean => {
    // TODO: Update this check once isSponsor is deprecated
    return (user?.isSponsorAdmin || user?.isSponsor) ?? false
  }

  const role = isSponsorAdmin() ? 'Sponsor Admin' : 'Subject Matter Expert'

  const staticLinks: LinksGroup[] = [
    // Disabled: https://infinitered.slack.com/archives/C07MY1RQ63S/p1731088788357509
    // { label: 'Dashboard', icon: IconTable, to: { name: 'dashboard' } },
    // { label: 'Getting Started', icon: IconRocket, to: { name: 'gettingStarted' } },
    {
      label: 'Modules',
      icon: IconHexagons,
      to: { name: 'contentModules' },
      additionalCurrentRoutes: ['contentModuleFormNew', 'contentModuleFormEdit', 'contentModule'],
    },
    { label: 'Expert Sidekicks', icon: IconUsers, to: { name: 'sidekicks' } },
    {
      label: 'Module Prioritization',
      icon: IconSortDescending2,
      to: getRoutePath('priority'),
    },
    ...(isSponsorAdmin()
      ? [
          {
            label: 'Topic Prioritization',
            icon: IconSortDescending2Filled,
            to: getRoutePath('topicPriorities'),
          },
        ]
      : []),
    ...(isSponsorAdmin()
      ? [
          {
            label: 'Metrics',
            icon: IconChartColumn,
            to: getRoutePath('metrics'),
          },
        ]
      : []),
    { label: 'Help', icon: IconHelpCircle, to: getRoutePath('help') },
  ]

  const [mobileDrawerOpened, { toggle: toggleMobileDrawer }] = useDisclosure()
  const mdBreakpoint = useMediaQuery(`(min-width: ${theme.breakpoints.md})`)

  const links = useMemo(
    () =>
      staticLinks.map((item, index) => (
        <NavbarLinksGroup {...item} key={typeof item.label === 'string' ? item.label : index} />
      )),
    [isSponsorAdmin],
  )

  useEffect(() => {
    const disposer = autorun(() => {
      chatStore.submitMockSMESKChatFn?.(undefined, undefined, { typing: true, skipQueue: true })

      chatStore.submitMockSMESKChatFn?.(
        <Stack gap="xs">
          <Text size="sm">{`Hello! I'm your Sidekick and I'm here to help you.`}</Text>
          <Text size="sm">{`The knowledge base for your Expert Sidekicks is made up of modules that you create. Think of it like training a new staff member on everything they need to know to serve your population. On this page, you can edit current modules or create new modules by uploading your existing files or manually entering content.`}</Text>
          <Text size="sm">{`What would you like to do? You can:`}</Text>
        </Stack>,
        [
          <Button
            key="create-module"
            component={Link}
            to={getRoutePath('contentModuleFormNew')}
            size="xs"
            leftSection={<IconHexagons stroke={1.5} size={20} />}>
            Create a Module
          </Button>,
          <Button
            key="view-metrics"
            component={Link}
            to={getRoutePath('metrics')}
            size="xs"
            leftSection={<IconChartColumn stroke={1.5} size={20} />}>
            View Metrics
          </Button>,
          <Button
            key="help"
            component={Link}
            to={getRoutePath('help')}
            size="xs"
            leftSection={<IconHelpCircle stroke={1.5} size={20} />}>
            See FAQs
          </Button>,
        ],
      )
    })

    return disposer
  }, [])

  return (
    <>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 72,
          breakpoint: 'md',
          collapsed: { mobile: !mobileDrawerOpened, desktop: false },
        }}
        aside={{
          width: 320,
          breakpoint: 'md',
          collapsed: { mobile: true },
        }}>
        <AppShell.Header>
          <Group h="100%" px="md" justify="space-between">
            <Group>
              <Burger opened={mobileDrawerOpened} onClick={toggleMobileDrawer} hiddenFrom="md" size="md" />
              <Image src={logo} w={theme.rem(40)} h={theme.rem(40)} radius="md" />
              <Text span size="xl" fw={600} className={classes.headerTitle} visibleFrom="md">
                Sidekick Platform
              </Text>
            </Group>

            {sponsorName && role && (
              <Group>
                <Group gap="xs" visibleFrom="sm">
                  <Text size="sm">Sponsor:</Text>
                  <Text size="sm" fw={600}>
                    {sponsorName}
                  </Text>
                </Group>

                <Divider orientation="vertical" visibleFrom="sm" />

                <Group gap="xs" visibleFrom="sm">
                  <Text size="sm">Role:</Text>
                  <Text size="sm" fw={600}>
                    {role}
                  </Text>
                </Group>

                {/* TODO: Uncomment once we have email in user object
              <Divider orientation="vertical" visibleFrom="sm" />

              <Group gap="xs">
                <Text size="sm" fw={600}>
                  jeff@sidekickwellness.com
                </Text>
              </Group> */}
              </Group>
            )}
          </Group>
        </AppShell.Header>

        <AppShell.Navbar
          bg="transparent"
          withBorder={false}
          onClick={e => {
            if (mdBreakpoint) return
            if (e.target === e.currentTarget) {
              toggleMobileDrawer()
            }
          }}>
          <Navbar>{links}</Navbar>
        </AppShell.Navbar>

        <AppShell.Main bg="gray.1">{props.children}</AppShell.Main>

        <AppShell.Aside style={{ zIndex: 230 }}>
          <Chat />
        </AppShell.Aside>

        <GlobalLoadingOverlay />
      </AppShell>

      <SearchModal
      // YULIANTODO: switch to https://mantine.dev/x/spotlight/
      // This is the same thing, but with prebuilt UI for search, limiting results, etc...
      />
    </>
  )
})
