import type { LineChartSeries } from '@mantine/charts'
import type { ErrorObject } from 'serialize-error'

import type { StringWithAutocomplete } from '@/types/helpers'

/*
 * Enums
 */
export enum DeliveryMode {
  Quote = 0,
  Adaptation = 1,
  Paraphrase = 2,
}

export enum ContentModuleStatus {
  Published = 'published',
  Draft = 'draft',
  Removed = 'removed',
  PendingApproval = 'pending_approval',
  Testing = 'testing',
}

/*
 * Common Types
 */
export type SafeRequestResponse<ResponseT, ErrorDataT = { message?: string }> =
  | { ok: false; data: ErrorDataT; error: ErrorObject | undefined }
  | { ok: true; data: ResponseT }

export type ConfigResponse = ImportMeta['env']

type StepOrTaskIdParams<T extends string = 'task_id' | 'step_id'> = T extends 'task_id'
  ? { task_id: string; step_id?: never }
  : { step_id: string; task_id?: never }

type StepOrTaskOrResourceIdParams<T extends string = 'task_id' | 'step_id' | 'resource_id'> = T extends 'task_id'
  ? { task_id: string; step_id?: never; resource_id?: never }
  : T extends 'step_id'
    ? { step_id: string; task_id?: never; resource_id?: never }
    : { resource_id: string; task_id?: never; step_id?: never }

/*
 * Request Types
 */
export type LoginParams = { username: string; password: string }

export type ForgotParams = { username: string; old_password: string; new_password: string }

export type LogoutParams = { access_token: string }

export type RefreshTokenParams = { refresh_token: string }

export type CreateContentModuleParams =
  | { content: string | null }
  | { file: File }
  | { file_name: string; s3_file: string }

export type UploadParams = { file_path: string; file_content: File }

export type PrepareUploadParams = { fileType: string }

export type GetContentModuleParams = { task_id: string }

export type GetContentParams = StepOrTaskIdParams

export type GetResourcesParams = StepOrTaskIdParams

export type GetFAQsParams = StepOrTaskIdParams

export type UpdateContentModuleParams = {
  task_id: string
  priority?: number
  name?: string
  title?: string
  short_description?: string
  long_description?: string
  are_step_sequential?: boolean
  select_esk?: string
  categories?: string[]
  mappings?: string[]
  s3_file?: string | null // UUID
  file_name?: string | null
  demographic?: string | null
  visible_to_msk?: boolean
  allowed_for_all_sponsors?: boolean
}

export type CreateFAQsParams = {
  faqs: Array<{
    question: string
    answer: string
  }>
  task_id?: string
  step_id?: string
}

export type UpdateFAQsParams = {
  faqs: Array<{
    faq_id: string
    question: string
    answer: string
  }>
  task_id?: string
  step_id?: string
}

export type DeleteFAQsParams = {
  faq_ids: string
  task_id?: string
  step_id?: string
}

export type UpdateContentTextParams = StepOrTaskIdParams &
  ({ text: string } | { file: File } | { file_name: string; s3_file: string })

export type CreateResourceParams = {
  file_name?: string | null
  file?: File
  s3_file?: string | null // UUID
  type: 'url' | 'image' | 'video' | 'text'
  title: string
  description: string
  data?: string
  keywords: string[]
  task_id?: string
  step_id?: string
}

export type UpdateResourceParams = CreateResourceParams & { resource_id: string }

export type DeleteResourceParams = {
  resource_id: string
  task_id?: string
  step_id?: string
}

export type GetStepsParams = { task_id: string }

export type CreateStepParams = ({ content: string } | { file: File } | { file_name: string; s3_file: string }) & {
  task_id: string
  preceding_step_id?: string
}

export type DeleteStepParams = {
  step_id: string
  task_id: string
  showToast?: boolean
}

export type UpdateStepParams = {
  step_id: string
  name?: string
  title?: string
  title_image?: string
  short_description?: string
  introduction?: string
  delivery_mode?: DeliveryMode
  section_objectives?: string
}

export type ChangeContentModuleStatusParams = {
  task_id: string
  status: `${ContentModuleStatus}`
}

export type GenerateMetadataParams = StepOrTaskOrResourceIdParams & {
  number_of_options?: number
  field?:
    | 'name'
    | 'title'
    | 'short_description'
    | 'long_description'
    | 'introduction'
    | 'description'
    | 'section_objectives'
}

export type GenerateDemographicParams = {
  task_id: string
}

export type GenerateStepObjectivesParams = {
  step_id: string
}

export type GenerateFAQsParams = StepOrTaskIdParams

/*
 * Response Types
 */
export type LoginResponse = {
  status?: number
  message: string
  authenticationResult?: {
    accessToken: string
    expiresIn: number
    tokenType: string
    refreshToken: string
    idToken: string
  }
}

export type LoginResponseError = {
  message: string
  code: 'NOT_AUTHORIZED' | 'NEW_PASSWORD_REQUIRED'
}

export type ForgotResponse = {
  message: string
}

export type ForgotResponseError = {
  code?: 'OLD_PASSWORD_INCORRECT'
  message: string
}

export type PrepareUploadResponse = {
  filePath: string
  fileName: string
  s3File: string
}

export type CreateContentModuleResponse = {
  taskId: string
  inProcess: boolean
  generated?: {
    name: string
    title: string
    longDescription: string
    shortDescription: string
  }
}

export type UpdateContentModuleResponse = {}

export type UpdateContentTextResponse = {
  message: string
  inProcess: boolean
}

export type GetESKsResponse = Array<{
  eskId: string
  name: string
  type: unknown
  introduction: string
  systemPrompt: string
  allowedForSwitchCm: boolean
  ttsId: string
  topicId: string
  active: boolean
  createdAt: string
  additionalInformation: string
  avatarId: string
  category: string
  createdBy: string
  expertise: string
}>

export type GetContentModuleResponse = {
  approvable: boolean
  access: unknown // NOT IMPLEMENTED
  areStepSequential: boolean | null
  categories: string[] | null
  createdAt: string | null
  createdBy: string | null
  editable: boolean
  mappings: string[] | null
  name: string | null
  selectEsk: string | null
  shortDescription: string | null
  status: `${ContentModuleStatus}` | null
  taskId: string | null
  title: string | null
  inProcess: boolean | null
  faqs: GetFAQsResponse | null
  demographic: string | null
  priority: number | null
  visibleToMsk: boolean | null
  allowedForAllSponsors: boolean | null
}

export type GetContentModulesResponse = GetContentModuleResponse[]

export type GetContentResponse = {
  contentId: string
  stepId: string | null
  taskId: string | null
  inProcess: boolean
  content: string
}

export type GetFAQsResponse = Array<{
  faqId: string
  createdAt: string
  question: string
  answer: string
}>

export type GetResourcesResponse = Array<{
  resourceId: string
  type: 'url' | 'image' | 'video' | 'text'
  fileName: string | null
  data: string
  title: string
  description: string
  keywords: string[] | null
  s3File?: string
  inProcess?: boolean
}>

export type GetStepsResponse = Array<{
  stepId: string
  name: string | null
  title: string | null
  titleImage: string | null
  sectionObjectives: string | null
  shortDescription: string | null
  introduction: string | null
  createdAt: string
  deliveryMode: DeliveryMode | null
  faqs: GetFAQsResponse | null
}>

export type CreateStepResponse = {
  stepId: string
  inProcess: boolean
  generated?: {
    introduction: string
    name: string
    overview: string
    shortDescription: string
    title: string
  }
}

export type ChangeContentModuleStatusResponse =
  | {
      status: boolean
      message?: string
    }
  | {
      errors: Array<{
        error: string
        field: string
      }>
    }

export type GenerateMetadataResponse = {
  generated: [
    {
      name: string
      title: string
      description: string
      shortDescription: string
      longDescription: string
      introduction: string
      section_objectives: string
    },
  ]
}

export type GenerateFAQsResponse = {
  questions: Array<{
    question: string
    answer: string
  }>
}

export type GenerateDemographicResponse = {
  demographic: string
}

export type GenerateStepObjectivesResponse = {
  objectives: string
}

export type CreateResourceResponse = {
  resourceId: string
  inProcess: boolean
}

export type UpdateResourceResponse = {
  message: string
  inProcess: boolean
}

export type CheckUserResponse = {
  access: {
    sme: boolean
    app: boolean
  }
  isSponsor: boolean
  isSponsorAdmin?: boolean
  isEditor: boolean
  sponsorId: string
  sponsorName: string
  userId: string

  // HACK: Below are additional fields that are the response of the API on stag and internal.
  message?: StringWithAutocomplete<'success'>
}

export type GetSponsorTopicsResponse = Array<{
  id: string
  key: string
  displayName: string
  subtopics: Array<{ id: string; key: string; displayName: string }>
}>

/**
 * Analytics Query Responses
 */

/**
 * A single entry item returned from the analytics API.
 * This is used for both the time series and histogram charts.
 */
export type ChartEntry = {
  label: string
  value: number
}

/**
 * A single entry in the timeline data returned from the analytics API.
 * The date is always present, and the rest are dynamic keys that are the labels of the series.
 */
export type TimeChartEntry = {
  date: string
  items: ChartEntry[]
}

/**
 * The response from the analytics API for the time series chart.
 */
export interface TimeSeriesResponse {
  data: TimeChartEntry[]
  total: number
  labels: string[]
}

/**
 * A single entry in the data returned after being processed by our hooks.
 * This is now in a format the chart components can use.
 */
export type LineChartEntry = {
  date: string
  [key: string]: string | number
}

/**
 * The timeseries data returned after being processed by our hooks.
 * This is now in a format the chart components can use.
 */
export type TimeSeriesResult = {
  data: LineChartEntry[]
  series: LineChartSeries[]
  total: number
  isLoading: boolean
  error: Error | null
}

/**
 * The histogram data returned from the analytics API.
 */
export interface HistogramResponse {
  data: HistogramEntry[]
  total: number
}

/**
 * A single entry in the histogram data returned from the analytics API.
 * This format can also be used by the chart components with minimal processing.
 */
export interface HistogramEntry {
  count: number
  data: number[]
  days: string[]
  dates?: string[]
  label: string
  labels: string[]
  aggregated_value: number
}

/**
 * The histogram data returned after being processed by our hooks.
 * This is now in a format the chart components can use.
 */
export type HistogramResult = {
  data: HistogramEntry[]
  isLoading: boolean
  error: Error | null
}

export type UpdateTopicPriorityParams = {
  topic_id: string
  priority: number
}
