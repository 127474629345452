import { Button, Text } from '@mantine/core'
import { IconCheck, IconList, IconRepeat } from '@tabler/icons-react'
import type { z } from 'zod'

import { theme } from '@/configs/theme'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { CMFormSection } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import type { FAQReviewModalProps } from '@/screens/ContentModuleFormScreen/FAQReviewModal'
import { FAQReviewModal } from '@/screens/ContentModuleFormScreen/FAQReviewModal'
import type { GenerateFAQsResponse, GenerateMetadataParams, GenerateMetadataResponse } from '@/services/api/api.types'

type ActionButtonProps = {
  action: 'accept' | 'regenerate'
  onAccept: () => void
  onRegenerate: () => void
}

const ActionButton = ({ action, onAccept, onRegenerate }: ActionButtonProps) => {
  switch (action) {
    case 'accept':
      return (
        <Button
          key="accept"
          size="xs"
          color="green.7"
          leftSection={<IconCheck stroke={1.5} size={20} />}
          onClick={onAccept}>
          Approve
        </Button>
      )
    case 'regenerate':
      return (
        <Button
          key="regenerate"
          size="xs"
          color="blue"
          leftSection={<IconRepeat stroke={1.5} size={20} />}
          onClick={onRegenerate}>
          Regenerate
        </Button>
      )
    default:
      return null
  }
}

export function useMockSMESKChatIntegration<T extends z.ZodType>(
  props: CMFormSection,
  updateServerFn?: (field: keyof z.infer<T>) => void,
) {
  const { chatStore, api } = useGlobalState()

  type AskSMESKForFieldOpts = {
    type: 'cm' | 'step' | 'resource'
    actions?: ('accept' | 'regenerate')[]
    apiParamField: GenerateMetadataParams['field']
    apiResponseField: keyof GenerateMetadataResponse['generated'][0]
    formField: keyof z.infer<T>
    userChatFieldLabel?: string
    numberOfOptions?: number
    skipUserChat?: boolean
  }
  async function askSMESKForField(opts?: AskSMESKForFieldOpts) {
    const {
      actions = [],
      apiParamField,
      numberOfOptions = 1,
      type,
      apiResponseField,
      userChatFieldLabel,
      skipUserChat = false,
      formField,
    } = opts ?? {}

    if (!apiParamField) return
    if (!apiResponseField) return
    if (!formField) return

    if (!skipUserChat) {
      const scopeLabel = type === 'cm' ? "module's" : type === 'step' ? "step's" : "supplemental resource's"

      chatStore.submitUserChatFn?.(
        <Text size="sm">
          Can you help me with the {scopeLabel} &quot;
          <Text component="span" inherit fw={600} fs="italic">
            {userChatFieldLabel ?? apiParamField}
          </Text>
          &quot; field?
        </Text>,
      )
    }

    chatStore.submitMockSMESKChatFn?.(undefined, undefined, { skipQueue: true, typing: true })

    const idParams =
      type === 'cm'
        ? { task_id: props.taskId! }
        : type === 'step'
          ? { step_id: props.stepId! }
          : { resource_id: props.resourceId! }

    const response = await api.safeRequest(api.generateMetadata, {
      ...idParams,
      number_of_options: numberOfOptions,
      field: apiParamField,
    })

    const firstGeneratedSuggestion = response.ok ? response.data.generated?.[0]?.[apiResponseField] : null

    if (firstGeneratedSuggestion) {
      chatStore.submitMockSMESKChatFn?.(
        <Text size="sm">
          How about:
          <br />
          <Text component="span" fw={600} fs="italic" inherit mt={theme.rem(8)} mb={theme.rem(8)}>
            &quot;{firstGeneratedSuggestion}&quot;
          </Text>
        </Text>,
        actions.map(a => (
          <ActionButton
            key={a}
            action={a}
            onAccept={() => {
              const form =
                type === 'cm' ? props.moduleForm : type === 'step' ? props.stepForm : props.resourceForm

              // @ts-expect-error -- form union type is not inferred correctly - this will be removed eventually
              form.setFieldValue(formField, firstGeneratedSuggestion)
              updateServerFn?.(formField)
            }}
            onRegenerate={() => askSMESKForField({ ...opts, skipUserChat: true } as AskSMESKForFieldOpts)}
          />
        )),
        { skipQueue: true },
      )
    } else {
      chatStore.submitMockSMESKChatFn?.("I'm sorry, but I can't provide you with a suggestion", [], { skipQueue: true })
    }
  }

  type AskSMESKForFAQsOpts = {
    type: 'cm' | 'step'
  }
  async function askSMESKForFAQs(opts?: AskSMESKForFAQsOpts) {
    const { type } = opts ?? {}

    function openFAQReviewModal(data: GenerateFAQsResponse['questions']) {
      const title = type === 'cm' ? 'Review Suggested FAQs for module' : 'Review Suggested FAQs for section'
      const subtitle =
        type === 'cm'
          ? 'Select or edit list of FAQs for the module and then save them.'
          : 'Select or edit list of FAQs for the section and then save them.'

      props.openModal?.({
        title,
        subtitle,
        Component: FAQReviewModal,
        ComponentProps: { type: type!, faqs: data } satisfies FAQReviewModalProps,
      })
    }

    const scopeLabel = type === 'cm' ? 'module' : 'step'

    chatStore.submitUserChatFn?.(`Can you help me come up with some FAQs for the ${scopeLabel}?`)

    chatStore.submitMockSMESKChatFn?.(undefined, undefined, { skipQueue: true, typing: true })

    const idParams = type === 'cm' ? { task_id: props.taskId! } : { step_id: props.stepId! }

    const response = await api.safeRequest(api.generateFAQs, idParams)

    if (response.ok && response.data.questions?.length) {
      chatStore.submitMockSMESKChatFn?.(
        `I came up with ${response.data.questions.length} FAQs for you:`,
        [
          <Button
            key="review"
            size="xs"
            color="blue"
            leftSection={<IconList stroke={1.5} size={20} />}
            onClick={() => openFAQReviewModal(response.data.questions)}>
            Review FAQs
          </Button>,
        ],
        { skipQueue: true },
      )
    } else {
      chatStore.submitMockSMESKChatFn?.("I'm sorry, but I can't come up with any FAQs.", [], { skipQueue: true })
    }
  }

  type AskSMESKForDemographicOpts = {
    actions?: ('accept' | 'regenerate')[]
    skipUserChat?: boolean
  }
  async function askSMESKForDemographic(opts?: AskSMESKForDemographicOpts) {
    const { actions = [], skipUserChat = false } = opts ?? {}

    if (!skipUserChat) {
      chatStore.submitUserChatFn?.(`Can you help me come up with a target audience for the module?`)
    }

    chatStore.submitMockSMESKChatFn?.(undefined, undefined, { skipQueue: true, typing: true })

    const response = await api.safeRequest(api.generateDemographic, { task_id: props.taskId! })
    const generatedResponse = response.ok ? response.data.demographic : null

    if (generatedResponse) {
      chatStore.submitMockSMESKChatFn?.(
        <Text size="sm">
          How about:
          <br />
          <Text component="span" fw={600} fs="italic" inherit mt={theme.rem(8)} mb={theme.rem(8)}>
            &quot;{generatedResponse}&quot;
          </Text>
        </Text>,
        actions.map(action => (
          <ActionButton
            key={action}
            action={action}
            onAccept={() => {
              props.moduleForm.setFieldValue('demographic', generatedResponse)
              updateServerFn?.('demographic')
            }}
            onRegenerate={() => askSMESKForDemographic({ ...opts, skipUserChat: true })}
          />
        )),
        { skipQueue: true },
      )
    } else {
      chatStore.submitMockSMESKChatFn?.("I'm sorry, but I can't come up with a demographic.", [], { skipQueue: true })
    }
  }

  async function askSMESKForStepObjectives(opts?: { actions?: ('accept' | 'regenerate')[]; skipUserChat?: boolean }) {
    const { actions = [], skipUserChat = false } = opts ?? {}

    if (!skipUserChat) {
      chatStore.submitUserChatFn?.('Can you help me come up with some objectives for this content?')
    }

    chatStore.submitMockSMESKChatFn?.(undefined, undefined, { skipQueue: true, typing: true })

    const response = await api.safeRequest(api.generateStepObjectives, { step_id: props.stepId! })
    const generatedObjectives = response.ok ? response.data.objectives : null

    if (generatedObjectives) {
      chatStore.submitMockSMESKChatFn?.(
        <Text size="sm">
          How about:
          <br />
          <Text component="span" fw={600} fs="italic" inherit mt={theme.rem(8)} mb={theme.rem(8)}>
            &quot;{generatedObjectives}&quot;
          </Text>
        </Text>,
        actions.map(action => (
          <ActionButton
            key={action}
            action={action}
            onAccept={() => {
              props.moduleForm.setFieldValue('stepObjectives', generatedObjectives)
              updateServerFn?.('stepObjectives')
            }}
            onRegenerate={() => askSMESKForStepObjectives({ ...opts, skipUserChat: true })}
          />
        )),
        { skipQueue: true },
      )
    } else {
      chatStore.submitMockSMESKChatFn?.("I'm sorry, but I can't come up with any objectives.", [], { skipQueue: true })
    }
  }

  return { askSMESKForField, askSMESKForFAQs, askSMESKForStepObjectives, askSMESKForDemographic }
}
