import { Box, Grid, Group, Loader, Text } from '@mantine/core'
import { useListState } from '@mantine/hooks'
import { IconGripVertical, IconSortDescending2Filled } from '@tabler/icons-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import { Screen } from '@/components/Screen'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { GetSponsorTopicsResponse } from '@/services/api/api.types'

export function TopicPrioritiesScreen() {
  const { api } = useGlobalState()
  const queryClient = useQueryClient()

  // Fetch topics from the server
  const topicsQuery = useQuery({
    queryKey: ['sponsor-topics'],
    queryFn: () => api.getSponsorTopics(),
  })

  // Local state for drag and drop reordering
  const [topics, handlers] = useListState(topicsQuery.data ?? [])

  // Keep local state in sync with server data
  React.useEffect(() => {
    if (topicsQuery.data) {
      handlers.setState(topicsQuery.data)
    }
  }, [topicsQuery.data])

  // Mutation to update priorities on the server
  const updatePriorityMutation = useMutation({
    mutationFn: (topics: GetSponsorTopicsResponse) => {
      return Promise.all(
        topics.map((topic, index, array) => {
          if (!topic?.id) return Promise.resolve()
          // Calculate priority (100 to 0) based on position
          const priority = Math.round(100 * (1 - index / (array.length - 1)))
          return api.updateTopicPriority({
            topic_id: topic.id,
            priority,
          })
        }),
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsor-topics'] })
    },
  })

  // Loading and error states
  if (topicsQuery.isError) {
    console.error('Error loading topics:', topicsQuery.error)
    return (
      <Screen title="Topic Prioritization" icon={IconSortDescending2Filled}>
        <Text c="red">Error loading topics</Text>
      </Screen>
    )
  }

  if (topicsQuery.isLoading) {
    return (
      <Screen title="Topic Prioritization" icon={IconSortDescending2Filled}>
        <Group justify="center" p="xl">
          <Loader />
        </Group>
      </Screen>
    )
  }

  if (!topics.length) {
    return (
      <Screen
        title="Topic Prioritization"
        description="Drag the Topics to rank them from highest priority to lowest"
        icon={IconSortDescending2Filled}>
        <Text c="dimmed" ta="center" p="xl">
          No topics found
        </Text>
      </Screen>
    )
  }

  // Render draggable topic list
  const items = topics.map((item, index) => (
    <Grid key={item.id ?? index} align="center" gutter={0}>
      <Grid.Col span={0.6}>
        {/* Number label that highlights on drag over */}
        <Text
          size="lg"
          fw={500}
          c="dimmed"
          ta="left"
          px="sm"
          ref={(el: HTMLParagraphElement | null) => {
            if (el) el.dataset.index = index.toString()
          }}>
          {index + 1}
        </Text>
      </Grid.Col>
      <Grid.Col span={8}>
        <Group
          p="md"
          mb="xs"
          bg="white"
          w="100%"
          style={{
            borderRadius: 8,
            border: '1px solid #eee',
            cursor: 'grab',
          }}
          draggable
          onDragStart={e => {
            e.currentTarget.style.opacity = '0.5'
            e.dataTransfer?.setData('text/plain', index.toString())
          }}
          onDragEnd={e => {
            e.currentTarget.style.opacity = '1'
            // Reset any highlighted numbers
            document.querySelectorAll(`[data-index="${index}"]`).forEach(el => {
              if (el instanceof HTMLElement) el.style.color = ''
            })
          }}
          onDragOver={e => {
            e.preventDefault()
            // Highlight the number when dragging over
            const numberEl = e.currentTarget.closest('.mantine-Grid-root')?.querySelector(`[data-index="${index}"]`)
            if (numberEl instanceof HTMLElement) numberEl.style.color = 'var(--mantine-color-blue-filled)'
          }}
          onDragLeave={e => {
            // Reset number highlight
            const numberEl = e.currentTarget.closest('.mantine-Grid-root')?.querySelector(`[data-index="${index}"]`)
            if (numberEl instanceof HTMLElement) numberEl.style.color = ''
          }}
          onDrop={e => {
            e.preventDefault()
            // Reset number highlight
            const numberEl = e.currentTarget.closest('.mantine-Grid-root')?.querySelector(`[data-index="${index}"]`)
            if (numberEl instanceof HTMLElement) numberEl.style.color = ''

            const fromIndex = Number(e.dataTransfer?.getData('text/plain'))
            const toIndex = index

            // Update local state and trigger server update
            handlers.reorder({ from: fromIndex, to: toIndex })
            updatePriorityMutation.mutate(topics)
          }}>
          <IconGripVertical size={18} style={{ cursor: 'grab' }} />
          <Box>{item.displayName}</Box>
        </Group>
      </Grid.Col>
    </Grid>
  ))

  return (
    <Screen
      title="Topic Priorities"
      description={<Text ta="left">Drag the topics to rank them from highest priority to lowest</Text>}
      icon={IconSortDescending2Filled}>
      <Box>{items}</Box>
    </Screen>
  )
}
