import type { BoxProps } from '@mantine/core'
import { Box, Button, Group, Text } from '@mantine/core'
import type { DropzoneProps } from '@mantine/dropzone'
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone'
import type { IconProps } from '@tabler/icons-react'
import { IconArrowDown, IconCloudUpload, IconX } from '@tabler/icons-react'
import { inflect } from 'inflection'
import type { ComponentType, ReactNode } from 'react'
import { useRef } from 'react'

import type { EmptyStateProps } from '@/components/EmptyState'
import { EmptyState } from '@/components/EmptyState'
import { InputLabel } from '@/components/InputLabel'
import { theme } from '@/configs/theme'
import * as classes from '@/styles/FileUpload.css'

export const textMimeTypes = [
  'text/plain',
  //'text/markdown'
]

export const acceptedFileUploadText =
  'Drag and drop TXT or PDF file here or choose a button below.\r\nNote: many file types can be saved as PDFs and then uploaded here.'
export const rejectedFileUploadText =
  'Only Text or PDF files are supported.\r\nNote: many file types can be saved as PDFs and then uploaded here.'
export const needsExtractionMimeTypes = [
  'application/pdf',
  // doc and docx
  //'application/msword',
  //'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

export const contentUploadMimeTypes = [...textMimeTypes, ...needsExtractionMimeTypes]

export interface FileUploadProps extends Omit<DropzoneProps, 'openRef' | 'title'> {
  WrapperProps?: BoxProps
  EmptyStateProps?: EmptyStateProps
  titles?: {
    idle?: string
    accept?: string
    reject?: string
  }
  icons?: {
    idle?: ComponentType<IconProps>
    accept?: ComponentType<IconProps>
    reject?: ComponentType<IconProps>
  }
  hint?: ReactNode
  hints?: {
    idle?: ReactNode
    accept?: ReactNode
    reject?: ReactNode
  }
  primaryActionLabel?: string
  secondaryActionLabel?: string
  onSecondaryActionClick?: () => void
  actionHidden?: boolean
  BottomSlot?: ReactNode
  TopSlot?: ReactNode
  label?: string
  error?: string
  description?: string
  withAsterisk?: boolean
}

export function FileUpload(props: FileUploadProps) {
  const {
    WrapperProps,
    titles,
    hint,
    hints,
    icons,
    error,
    description,
    label,
    maxFiles = 10,
    withAsterisk,
    primaryActionLabel,
    actionHidden,
    BottomSlot = null,
    TopSlot = null,
    EmptyStateProps,
    loading,
    secondaryActionLabel,
    onSecondaryActionClick,
    ...DropzoneProps
  } = props

  const {
    idle: IconIdle = IconCloudUpload,
    accept: IconAccept = IconArrowDown,
    reject: IconReject = IconX,
  } = icons ?? {}

  const hasControls = !actionHidden
  const defaultHint = hint ?? `Drag & drop ${inflect('file', maxFiles)} here to upload.`

  const openRef = useRef<() => void>(null)

  return (
    <Box>
      <Box
        {...WrapperProps}
        className={theme.cx(classes.wrapper, WrapperProps?.className, hasControls && classes.wrapperWControls)}>
        {label && <InputLabel label={label} withAsterisk={withAsterisk} />}

        <Dropzone
          radius="md"
          accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
          maxFiles={maxFiles}
          {...DropzoneProps}
          loading={loading}
          openRef={openRef}
          classNames={{
            root: theme.cx(classes.dropzone, DropzoneProps.className, !!error && classes.dropzoneWError),
            inner: theme.cx(
              classes.dropzoneInner,
              hasControls && classes.dropzoneInnerWControls,
              DropzoneProps?.activateOnClick === false && classes.dropzoneInnerWPointerEvents,
            ),
          }}>
          {TopSlot}

          <Dropzone.Idle>
            <EmptyState
              size="sm"
              title={titles?.idle ?? `Upload ${inflect('File', maxFiles)}`}
              description={hints?.idle ?? defaultHint}
              Icon={IconIdle}
              DescriptionProps={{ maw: '100%' }}
              {...EmptyStateProps}
            />
          </Dropzone.Idle>

          <Dropzone.Accept>
            <EmptyState
              size="sm"
              title={titles?.accept ?? `Drop ${inflect('File', maxFiles)} Here...`}
              description={hints?.accept ?? defaultHint}
              Icon={IconAccept}
              IconProps={{ color: theme.colors.primaryColors[6] }}
              DescriptionProps={{ maw: '100%' }}
              {...EmptyStateProps}
            />
          </Dropzone.Accept>

          <Dropzone.Reject>
            <EmptyState
              size="sm"
              title={titles?.reject ?? `Unsupported ${inflect('File', maxFiles)} Type or Size...`}
              description={hints?.reject ?? defaultHint}
              Icon={IconReject}
              IconProps={{ color: theme.colors.error }}
              DescriptionProps={{ maw: '100%' }}
              {...EmptyStateProps}
            />
          </Dropzone.Reject>

          {BottomSlot}
        </Dropzone>

        {hasControls && (
          <Group gap="xl" className={theme.cx(classes.controlsWrapper, loading && classes.controlsWhileLoading)}>
            <Button className={classes.selectControl} onClick={() => openRef.current?.()}>
              {primaryActionLabel ?? `Select ${inflect('File', maxFiles)}`}
            </Button>

            {secondaryActionLabel && (
              <Button
                size="md"
                variant="light"
                className={theme.cx(classes.selectControl, classes.secondaryAction)}
                onClick={onSecondaryActionClick}>
                {secondaryActionLabel}
              </Button>
            )}
          </Group>
        )}
      </Box>

      {error ? (
        <Text className={classes.error}>{error}</Text>
      ) : description ? (
        <Text c="dimmed" className={classes.description}>
          {description}
        </Text>
      ) : null}
    </Box>
  )
}

// YULIANTODO: When implementing the file upload endpoints, we can add a upload progress tracker and use: https://mantine.dev/x/nprogress/
