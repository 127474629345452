import { usePostHog } from 'posthog-js/react'

import { useGlobalState } from '@/hooks/useGlobalState'
import { NotFoundScreen } from '@/screens/404Screen'
import { ChangePasswordScreen } from '@/screens/ChangePasswordScreen'
import { ContentModuleFormScreen } from '@/screens/ContentModuleFormScreen/ContentModuleFormScreen'
import { ContentModuleScreen } from '@/screens/ContentModuleScreen/ContentModuleScreen'
import { ContentModulesScreen } from '@/screens/ContentModulesScreen'
import { HelpScreen } from '@/screens/HelpScreen'
import { LoginScreen } from '@/screens/LoginScreen'
import { MetricsScreen } from '@/screens/MetricsScreen'
import { PriorityScreen } from '@/screens/PriorityScreen'
import { SidekicksScreen } from '@/screens/SidekicksScreen'
import { TopicPrioritiesScreen } from '@/screens/TopicPrioritiesScreen'
import type { StringWithAutocomplete } from '@/types/helpers'
import type { RouteDef, RouteRedirectResponse, RouteRenderConditionResponse } from '@/utils/navigation'
import { getRoutePath } from '@/utils/navigation'

export const routes = {
  home: {
    path: '/',
    renderConditionFn: homeRedirect,
    routes: {
      auth: {
        path: '/auth',
        renderConditionFn: authRenderCondition,
        redirectFn: authRedirect,
        routes: {
          login: { path: '/login', component: LoginScreen },
          changePassword: { path: '/change-password', component: ChangePasswordScreen },
          signup: { path: '/signup' },
        },
      },
      dashboard: {
        path: '/dashboard',
        renderConditionFn: dashboardRenderCondition,
        redirectFn: disabledPagesRedirect,
        routes: {
          gettingStarted: { path: '/getting-started', redirectFn: disabledPagesRedirect },
          sidekicks: {
            path: '/expert-sidekicks',
            component: SidekicksScreen,
            renderConditionFn: sponsorRenderCondition,
            routes: {
              sidekicksForm: { path: '/:id', component: NotFoundScreen },
            },
          },
          metrics: { path: '/metrics', component: MetricsScreen },
          contentModules: {
            path: '/modules',
            component: ContentModulesScreen,
            routes: {
              contentModuleFormEdit: { path: '/:id/edit', component: ContentModuleFormScreen },
              contentModuleFormNew: { path: '/new', component: ContentModuleFormScreen },
              contentModule: { path: '/:id', component: ContentModuleScreen },
            },
          },
          priority: {
            path: '/priority',
            component: PriorityScreen,
          },
          topicPriorities: {
            path: '/topic-priorities',
            component: TopicPrioritiesScreen,
            renderConditionFn: sponsorRenderCondition,
          },
          help: { path: '/help', component: HelpScreen },
        },
      },
    },
  },
} as const satisfies RouteDef['routes']

export type RouteParams = {
  home: undefined

  auth: undefined
  login: undefined
  signup: undefined
  changePassword: undefined

  dashboard: undefined
  gettingStarted: undefined
  sidekicks: undefined
  sidekicksForm: { id: StringWithAutocomplete<'new'> }
  contentModules: undefined
  contentModule: { id: string }
  contentModuleFormNew: undefined
  contentModuleFormEdit: { id: string }
  metrics: undefined
  help: undefined
  priority: undefined
  topicPriorities: undefined
}

function logUser(_page: string) {
  const posthog = usePostHog()
  const { authenticationStore } = useGlobalState()
  const { user } = authenticationStore
  if (user) {
    posthog?.identify(user?.userId, {
      isSponsor: user?.isSponsor,
      isSponsorAdmin: user?.isSponsorAdmin,
      sponsorId: user?.sponsorId,
      sponsorName: user?.sponsorName,
    })
  }
}

function homeRedirect(): RouteRedirectResponse {
  const { authenticationStore } = useGlobalState()
  const { isAuthenticated } = authenticationStore

  if (isAuthenticated) {
    return { redirectTo: getRoutePath('dashboard') }
  } else {
    return { redirectTo: getRoutePath('auth') }
  }
}

function authRedirect(): RouteRedirectResponse {
  // logUser('auth')
  return { redirectTo: getRoutePath('login') }
}

function authRenderCondition(): RouteRenderConditionResponse {
  const { authenticationStore } = useGlobalState()
  const { isAuthenticated } = authenticationStore
  logUser('authRenderCondition')

  return !isAuthenticated || { redirectTo: getRoutePath('dashboard') }
}

function dashboardRenderCondition(): RouteRenderConditionResponse {
  const { authenticationStore } = useGlobalState()
  const { isAuthenticated } = authenticationStore
  // logUser('dashboardRenderCondition')

  return isAuthenticated || { redirectTo: getRoutePath('auth') }
}

function disabledPagesRedirect(): RouteRedirectResponse {
  return { redirectTo: getRoutePath('contentModules') }
}

function sponsorRenderCondition(): RouteRenderConditionResponse {
  const { authenticationStore } = useGlobalState()
  const { user, isAuthenticated } = authenticationStore
  // logUser('sponsorRenderCondition')

  return !isAuthenticated
    ? { redirectTo: getRoutePath('auth') }
    : isAuthenticated && (!!user?.isSponsor || !!user?.isSponsorAdmin)
}
