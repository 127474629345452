import { Avatar, Group, Image, Pill, Text } from '@mantine/core'
import { IconPhotoScan } from '@tabler/icons-react'
import { useMemo } from 'react'

import avatars from '@/assets/avatars'
import { CollapsibleContentSection } from '@/components/CollapsibleContentSection'
import { EmptyState } from '@/components/EmptyState'
import { TableContentGrid } from '@/components/TableContentGrid'
import { useGlobalState } from '@/hooks/useGlobalState'

import { useContentModuleScreenData } from './ContentModuleScreen'
import { RadioContentCell } from './RadioContentCell'

export function ModuleSection() {
  const { configStore } = useGlobalState()
  const data = useContentModuleScreenData()

  const eskName = useMemo(
    () =>
      data.esksQuery.data?.find(e => e.eskId === data.cmQuery.data?.selectEsk)?.name ??
      `Avatar ${data.cmQuery.data?.selectEsk ?? ''}`,
    [data.cmQuery.data?.selectEsk, data.esksQuery.data],
  )

  const eskAvatar = useMemo(() => {
    if (!data.esksQuery.data) return undefined

    const esk = data.esksQuery.data.find(e => e.eskId === data.cmQuery.data?.selectEsk)

    return esk ? avatars[esk.avatarId] : undefined
  }, [data.cmQuery.data?.selectEsk, data.esksQuery.data])

  return (
    <CollapsibleContentSection
      id="module-details"
      title="Module Details"
      subtitle="General module information and user experience settings."
      ContentElement={
        <TableContentGrid
          headingFlexWidth="245px"
          rows={[
            { heading: 'Title', content: <Text fw={600}>{data.cmQuery.data?.title}</Text> },
            {
              heading: 'Expert Sidekick',
              content: (
                <Group align="center">
                  <Avatar src={eskAvatar} variant={!!eskAvatar ? 'transparent' : 'light'} radius="sm" size="lg" />
                  <Text>{eskName}</Text>
                </Group>
              ),
            },
            { heading: 'Module Description', content: data.cmQuery.data?.shortDescription },
            { heading: 'Topic', content: data.cmQuery.data?.categories?.join(', ') },
            {
              heading: 'Sub-Topics',
              content: (
                <Group>
                  {data.cmQuery.data?.mappings?.map(m => (
                    <Pill key={m} size="lg">
                      {m}
                    </Pill>
                  ))}
                </Group>
              ),
            },
            {
              heading: 'Module Flow',
              content: (
                <RadioContentCell
                  items={[
                    { label: 'User Directed', selected: !data.cmQuery.data?.areStepSequential },
                    { label: 'Sequential', selected: !!data.cmQuery.data?.areStepSequential },
                  ]}
                />
              ),
            },
            //{ heading: 'Expiration Date', content: <ComingSoon /> },
            //{ heading: 'Due Date', content: <ComingSoon /> },
            { heading: 'Target Audience', content: data.cmQuery.data?.demographic || 'Not specified' },
            {
              heading: 'Module Objectives',
              content: data.stepsQuery.data?.[0]?.sectionObjectives || 'Not specified',
            },
            {
              heading: 'Module Intro Image',
              content: data.stepsQuery.data?.[0]?.titleImage ? (
                <Image
                  fit="contain"
                  radius="md"
                  h="auto"
                  mah="300px"
                  w="auto"
                  maw="100%"
                  src={`${configStore.serverBaseUrl}/files/${data.stepsQuery.data?.[0]?.titleImage}`}
                />
              ) : (
                <EmptyState size="sm" Icon={IconPhotoScan} title="No Intro Image Uploaded" />
              ),
            },
            // {
            //   heading:
            //     'Which media can the Expert Sidekick use in personalizing content delivery to member preferences?',
            //   content: <ComingSoon />,
            // },
          ]}
        />
      }
    />
  )
}
