import { Anchor, Avatar, Paper, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { IconUsers } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
// import humanizeDuration from 'humanize-duration'
import type { MRT_ColumnDef } from 'mantine-react-table'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Link } from 'wouter'

import avatars from '@/assets/avatars'
import { Screen } from '@/components/Screen'
import { HeaderCellWithTooltip, Table } from '@/components/Table'
import { useGlobalState } from '@/hooks/useGlobalState'
import type { GetESKsResponse } from '@/services/api/api.types'
import { formatDate } from '@/utils/dates'
import { getRoutePath } from '@/utils/navigation'

export const SidekicksScreen = observer(function SidekicksScreen() {
  const { api } = useGlobalState()

  const topicsQuery = useQuery({
    queryKey: ['sponsor', 'topics'],
    queryFn: api.getSponsorTopics,
    initialData: [],
  })

  const esksQuery = useQuery({
    queryKey: ['cm', 'esks'],
    queryFn: api.getESKs,
    enabled: topicsQuery.isFetched && topicsQuery.isSuccess,
    initialData: [],
    select: data =>
      data.map(d => ({ ...d, topicDisplayName: topicsQuery.data?.find(t => t.id === d.topicId)?.displayName ?? '' })),
  })

  function deleteSidekick() {
    modals.openConfirmModal({
      title: 'Delete Expert Sidekick?',
      centered: true,
      children: (
        <Text size="sm">{`Are you sure you want to delete this Expert Sidekick? This action cannot be undone.`}</Text>
      ),
      labels: { confirm: 'Delete Expert Sidekick', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      groupProps: { mt: 'xl' },
      onConfirm: () => {},
    })
  }

  const ScreenDescription = useMemo(
    () => (
      <>
        <Text>
          Expert Sidekicks are your representatives. You teach them what you want them to know, and they work one-on-one
          with your people to give them focused, personalized support.
        </Text>

        <Text>
          The{' '}
          <Anchor component={Link} to={getRoutePath('contentModules')}>
            Modules
          </Anchor>{' '}
          page is where you create their knowledge.
        </Text>
      </>
    ),
    [],
  )

  return (
    <Screen title="Expert Sidekicks" icon={IconUsers} description={ScreenDescription}>
      <Paper radius="md" p="lg" shadow="sm" pos="relative">
        <Table
          data={esksQuery.data}
          columns={sidekickCols}
          // rowActionsInclude={['view', 'archive', 'delete']}
          rowActionsTooltipLabels={{
            view: `View Sidekick's avatar`,
            archive: 'Archive Sidekick',
            delete: 'Permanently delete Sidekick',
          }}
          onRowActionClick={action => {
            if (action === 'delete') {
              deleteSidekick()
            }
          }}
          entity={{ singular: 'Expert Sidekick', plural: 'Expert Sidekicks' }}
          // toolbarActionsInclude={['new']}
          displayColumnDefOptions={{ 'mrt-row-actions': { size: 138 } }}
        />

      </Paper>
    </Screen>
  )
})

const sidekickCols: MRT_ColumnDef<GetESKsResponse[0]>[] = [
  // {
  //   id: 'edit',
  //   header: '',
  //   Cell: _props => <TableRowActionButton action="edit" tooltipLabel="Edit Sidekick" />,
  //   grow: false,
  //   size: 60,
  // },
  {
    id: 'avatar',
    header: '',
    grow: false,
    size: 88,
    Cell: props => {
      const avatar = avatars[props.row.original.avatarId]

      return <Avatar variant={!!avatar ? 'transparent' : 'light'} radius="sm" size="lg" src={avatar} />
    },
  },
  {
    accessorKey: 'name',
    header: 'Expert Sidekick',
    grow: true,
    size: 140,
    Cell: props => props.cell.getValue<string>() || `Avatar ${props.row.original.eskId}`,
  },
  {
    accessorKey: 'topicDisplayName',
    header: 'Topic',
    Header: ({ column }) => <HeaderCellWithTooltip column={column} tooltip="Area of expertise" />,
    grow: false,
    size: 280,
  },
  // {
  //   id: 'usageTime',
  //   // @ts-expect-error -- index signature is different
  //   accessorFn: row => mockTableData[row.eskId]?.usageTime,
  //   header: 'Interaction Time',
  //   Header: ({ column }) => (
  //     <HeaderCellWithTooltip column={column} tooltip="Total time Sidekick has spent interacting with users" />
  //   ),
  //   Cell: props => humanizeDuration(props.cell.getValue<number>(), { round: true, units: ['m'] }),
  //   grow: false,
  // },
  // {
  //   id: 'modules',
  //   // @ts-expect-error -- index signature is different
  //   accessorFn: row => mockTableData[row.eskId]?.modules,
  //   header: 'Modules Using',
  //   Cell: props => props.cell.getValue<string[]>()?.length ?? '',
  //   grow: false,
  //   size: 138,
  // },
  {
    id: 'createdAt',
    accessorFn: row => row.createdAt,
    header: 'Date Created',
    Cell: props => formatDate(props.cell.getValue<string>(), { format: 'll' }),
    grow: false,
    size: 128,
  },
  // {
  //   id: 'status',
  //   // @ts-expect-error -- index signature is different
  //   accessorFn: row => mockTableData[row.eskId]?.status ?? 'Draft',
  //   header: 'Status',
  //   grow: false,
  //   Cell: props => (
  //     <Badge
  //       color={
  //         {
  //           'Pending Approval': 'blue',
  //           Deployed: 'green',
  //           Draft: 'orange',
  //           Archived: 'gray',
  //         }[props.cell.getValue<string>()] ?? 'gray'
  //       }
  //       variant="filled">
  //       {props.cell.getValue<string>() ?? 'Removed'}
  //     </Badge>
  //   ),
  // },
]
