import type { HelpSection } from './helpContent.types'

export const helpContent: HelpSection[] = [
  {
    title: 'General',
    subSections: [
      {
        entries: [
          {
            question: 'What is the Content Portal?',
            answer:
              'The Content Portal is an interface that allows Subject Matter Experts to design Content Modules and distribute them to the Members of their Sponsor organization.',
          },
          {
            question: 'What is a Content Module?',
            answer:
              'A Content Module is a collection of materials about a subject that are stored together to be presented to Members.',
          },
          {
            question: 'What is an Expert Sidekick?',
            answer:
              'An Expert Sidekick is a digital human that is knowledgeable about a specific Topic. They get their knowledge from the Content Modules created by Subject Matter Experts. The Expert Sidekick’s goal is to help the Member gain knowledge in the Subject Matter Expert’s field.',
          },
          {
            question: 'What is a Personal Sidekick?',
            answer:
              'A Personal Sidekick is the Member’s confidante, concierge, and assistant--all in their pocket. They help the Member figure out what they need to do, and who they need to speak to so they can get their task done.',
          },
          {
            question: 'How do I create a new Content Module?',
            answer: 'On the Content Module screen on the right side of the screen, click the New Module button.',
          },
        ],
      },
    ],
  },
  {
    title: 'Creating a Content Module',
    subSections: [
      {
        title: 'Step 1: Designate an Expert Sidekick',
        entries: [
          {
            question: 'Why do I have to select a Topic?',
            answer:
              'Picking a Topic will also pick the Expert Sidekick your Content Module will be assigned to. There is an Expert Sidekick for each Topic listed in the system.',
          },
          {
            question: 'Who creates the Topics?',
            answer: 'The Program Administrator with your Sponsor Organization creates the list of Topics.',
          },
          {
            question: 'What if there is not a relevant Topic for my Content?',
            answer:
              'The Program Administrator with your Sponsor Organization creates the list of Topics. You can suggest additional Topics to them.',
          },
          {
            question: 'What are Sub-Topics?',
            answer:
              'Sub-Topics give more resolution to what is in a Topic. They are defined by your Program Administrator and they help the Sidekicks get the right content to the right Member.',
          },
          {
            question: 'Can I change the Expert Sidekick?',
            answer: 'You can change the Expert Sidekick by changing the Topic.',
          },
        ],
      },
      {
        title: 'Step 2: Add Content',
        entries: [
          {
            question: 'What file formats can I upload?',
            answer:
              'The file formats for the main content and supplemental text are: .txt, .docx, .md, and .pdf. Supplemental image formats are: .png, .jpeg, .gif.',
          },
          {
            question: 'What is a Section?',
            answer:
              'You will be able to create multiple Sections within your Content Module--think of the different sections in an outline of a document.',
          },
          {
            question: 'Where did the Title come from?',
            answer:
              'The system uses AI to suggest a Title based on the content that you uploaded. You can accept it by clicking the Approve button or modify it by simply typing an alternative Title.',
          },
          {
            question: 'Why do I need to add a Section Overview?',
            answer: 'The Expert Sidekick will use the Section Overview to describe your content to Members.',
          },
          {
            question: 'What is a Section Intro?',
            answer: 'The Section Intro will be used by the Expert Sidekick to introduce your content to Members.',
          },
          {
            question: 'Why do I need FAQs?',
            answer:
              'FAQs are helpful for Members and Sidekicks. Members can ask for FAQs if they aren’t sure what to ask. Sidekicks have easy access to some of the most common questions so they can answer them quickly.',
          },
          {
            question: 'Do I have to accept all the FAQs the Sidekick generates?',
            answer:
              'No, they are suggestions. You can accept some or all, and edit any questions and answers that aren’t quite right.',
          },
          {
            question: 'What are Supplemental Resources and why are they important?',
            answer:
              'You can add Supplemental Resources—like links, images, or videos, or extra text—to extend your content. These Resources will be shown by the Expert Sidekick when relevant in the context of a conversation. Supplemental text is used to provide additional information to the Expert Sidekick.',
          },
          {
            question: 'Can I add more content?',
            answer:
              'In the near future, you will be able to add additional Sections to your Content Module. For now, you can add more content as Supplemental Resources.',
          },
        ],
      },
      {
        title: 'Step 3: Describe Module',
        entries: [
          {
            question: 'Why do we have to describe the Content Module? What do all these fields do?',
            answer:
              'Describing the Content Module accurately helps the Sidekicks bring up the relevant information at the right time in the conversation with the Member.',
          },
          {
            question: 'Can I change what the AI suggests for each field?',
            answer:
              'Yes. You can edit the Sidekick’s suggestions simply by clicking in the field and entering new text.',
          },
          {
            question: 'What is a Target Audience?',
            answer:
              'Use this field to describe what audience your content is best for. For example, your content might be best for new Veterans who have been recently discharged or best for people over the age of 65.  The system will prioritize this Content for people who are similar to the Target Audience you describe.',
          },
          {
            question: 'What are Module Objectives?',
            answer:
              'Module Objectives are the Expert Sidekick’s objectives as they work through the content. The Expert Sidekicks will try to proactively cover the topics and tasks you list here.',
          },
        ],
      },
      {
        title: 'Step 4: Save Module',
        entries: [
          {
            question: 'Will my Content Module become available to Members when I save it?',
            answer:
              'No. Saving your Content Module will not make it available to Member. It will only be available to them once you have submitted your Content Module for approval and your Program Administrator has published it.',
          },
        ],
      },
    ],
  },
  {
    title: 'Managing a Content Module',
    subSections: [
      {
        entries: [
          {
            question: 'Where do I manage my Content Module?',
            answer: 'In the Navigation panel on the far left of your screen, click on the Modules button.',
          },
          {
            question: 'What can I do with my Content Modules?',
            answer: 'Under “My Modules,” you can do several things:',
            bullets: [
              'Edit your module by clicking the pencil button at the left of your Content Module’s title.',
              'Preview the content inside your module by clicking the eye button at the left of your Content Module’s title.',
              'Test in App by clicking the icon under Deploy on the far right and selecting “Test in App”',
              'Submit for Approval by clicking the icon under Deploy on the far right and selecting “Submit for Approval.”',
              'Delete your Content Module by clicking the icon under Deploy on the far right and selecting “Delete.”',
            ],
          },
          {
            question: 'What is Test in App?',
            answer:
              'Test in App allows you to test your Content Module in the Sidekick app, but only you will be able to see the Content Module. This lets you test the experience of your Content Module from the Member perspective, but without anyone else being able to access it.',
          },
          {
            question: 'What is Submit for Approval?',
            answer:
              'When you Submit for Approval, you let your Program Administrator know that your Content Module is ready for them to review. They will look it over and then Publish it to the Sidekick app, if they think it is ready to go.',
          },
          {
            question: 'Can I edit a Content Module?',
            answer:
              'You can absolutely go back and Edit your content, as long as it is not Published. To Edit the content:',
            steps: [
              'On the Modules screen, to the left of the Content Module, click the pencil icon to get into Edit mode.',
              'Modify your content as you see fit.',
              'When ready, click Save Module.',
            ],
          },
          {
            question: 'Why can’t I edit all the Content Modules?',
            answer: 'Content Module editing is restricted to the owner of the Content Module.',
          },
          {
            question: 'Can I see Content Modules other Subject Matter Experts have created?',
            answer:
              'Yes, you can view the content in a view-only mode.  Under Sponsor Modules, you can click on the small eye to preview the content put into that Content Module.',
          },
          {
            question: 'Can I delete a Content Module?',
            answer:
              'You can Delete a Content Module as long as it is in Draft. Once the Content Module has been published,  only the Program Administrator can delete it.',
          },
          {
            question: 'Can I unpublish a Content Module?',
            answer: 'Only your Program Administrator can Unpublish your Content Module.',
          },
        ],
      },
    ],
  },
]
