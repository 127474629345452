import { createTheme, MantineProvider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import type { FallbackRender } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import type { PostHogConfig } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useEffect, useState } from 'react'
import { Route, Switch } from 'wouter'

import { AuthLayout } from '@/components/AuthLayout'
import { DashboardLayout } from '@/components/DashboardLayout'
import { DevModal } from '@/components/DevModal'
import { PWAUpdateDialog } from '@/components/PWAUpdateDialog'
import { Router } from '@/components/Router'
import { dateProviderSettings, modalDefaults } from '@/configs/componentDefaults'
import { mantineTheme } from '@/configs/theme'
import { useInitialGlobalState } from '@/hooks/useGlobalState'
import { NotFoundScreen } from '@/screens/404Screen'
import { ErrorBoundaryScreen } from '@/screens/ErrorBoundaryScreen'
import { createRouteStack } from '@/utils/navigation'

const routeStack = createRouteStack({ debug: false })
const theme = createTheme(mantineTheme)

const options: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
  // Capture anonymous events by default and only capture identified events when specified
  person_profiles: 'identified_only',
}

const App = observer(function App() {
  const { globalState, isGlobalStateRehydrated } = useInitialGlobalState()
  const { configStore, isAppReady, authenticationStore } = globalState
  const { isAuthenticated } = authenticationStore

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (isGlobalStateRehydrated && isAppReady) {
      setIsReady(true)
    }
  }, [isGlobalStateRehydrated, isAppReady])

  const Layout = isAuthenticated ? DashboardLayout : AuthLayout

  if (!isReady) return null

  return (
    <PostHogProvider apiKey={configStore.posthogKey} options={options}>
      <Router>
        <QueryClientProvider client={globalState.api.queryClient}>
          <MantineProvider theme={theme}>
            <DatesProvider settings={dateProviderSettings}>
              <ModalsProvider modalProps={{ ...modalDefaults.defaultProps }}>
                <Layout>
                  <Switch>
                    {routeStack}
                    <Route component={NotFoundScreen} />
                  </Switch>
                </Layout>

                <DevModal />
                <PWAUpdateDialog />
              </ModalsProvider>

              <Notifications />
            </DatesProvider>
          </MantineProvider>
        </QueryClientProvider>
      </Router>
    </PostHogProvider>
  )
})

export function FallBackApp(props: Parameters<FallbackRender>[0]) {
  return (
    <MantineProvider theme={theme}>
      <ModalsProvider modalProps={modalDefaults.defaultProps}>
        <ErrorBoundaryScreen {...props} />
        <PWAUpdateDialog />
      </ModalsProvider>
    </MantineProvider>
  )
}

export default App
