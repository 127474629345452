import type { QueryClientConfig } from '@tanstack/react-query'
import { QueryClient } from '@tanstack/react-query'
import type { AxiosInstance, Method } from 'axios'
import axios, { type AxiosRequestConfig } from 'axios'
import { camelCase } from 'change-case/keys'
import merge from 'deepmerge'
import type { ErrorObject } from 'serialize-error'
import { serializeError } from 'serialize-error'

import { Consts } from '@/configs/consts'
import type * as T from '@/services/api/api.types'
import type { UpdateContentTextResponse } from '@/services/api/api.types'

export class Api {
  private httpClient: AxiosInstance
  queryClient: QueryClient

  constructor(axiosConfig: AxiosRequestConfig & { authToken?: string } = {}, tanstackConfig: QueryClientConfig = {}) {
    this.httpClient = axios.create(
      merge(
        {
          baseURL: Consts.serverBaseUrl,
          headers: { 'Content-Type': 'application/json' },
        },
        axiosConfig,
      ),
    )

    if (axiosConfig.authToken) {
      this.setAuthToken(axiosConfig.authToken)
    }

    if (axiosConfig.baseURL) {
      this.setBaseUrl(axiosConfig.baseURL)
    }

    this.queryClient = new QueryClient(tanstackConfig)
  }

  setAuthToken(token: string | null) {
    this.httpClient.defaults.headers.common['Authorization'] = !!token ? `Bearer ${token}` : null
  }

  setBaseUrl(url: string) {
    this.httpClient.defaults.baseURL = url
  }

  async request<ResponseT>(
    path: string,
    method: Method,
    data: any = undefined,
    config?: AxiosRequestConfig & { camelcase?: boolean },
  ): Promise<ResponseT> {
    const { camelcase = true, ...axiosConfig } = config ?? {}

    const response = await this.httpClient.request<ResponseT>({
      url: path,
      method,
      data: method !== 'get' ? data : undefined,
      params: method === 'get' ? data : undefined,
      ...axiosConfig,
    })

    if (camelcase) {
      return camelCase(response.data, 999) as ResponseT
    } else {
      return response.data as ResponseT
    }
  }

  async safeRequest<RequestT extends (...args: any[]) => Promise<any>, ErrorDataT = { message?: string }>(
    requestFn: RequestT,
    ...args: Parameters<RequestT>
  ): Promise<T.SafeRequestResponse<Awaited<ReturnType<RequestT>>, ErrorDataT>> {
    try {
      const response = await requestFn(...args)

      return { ok: true as const, data: response }
    } catch (error) {
      const e = error as Error & { response: { data: ErrorDataT } }

      let parsedError: ErrorObject | undefined

      if (e instanceof Error) {
        parsedError = serializeError(e)
      }

      return { ok: false as const, data: e.response.data, error: parsedError }
    }
  }

  getRuntimeConfig = async () => {
    return this.request<T.ConfigResponse>(`${window.location.origin}/config.json`, 'get', undefined, {
      camelcase: false,
    })
  }

  /**
   * Authentication
   */
  login = async (params: T.LoginParams) => {
    return this.request<T.LoginResponse>('/login', 'post', params)
  }

  logout = async (params: T.LogoutParams) => {
    return this.request('/logout', 'post', params)
  }

  forgot = async (params: T.ForgotParams) => {
    return this.request<T.ForgotResponse>('/forgot', 'post', params)
  }

  refreshToken = async (params: T.RefreshTokenParams) => {
    return this.request<T.LoginResponse>('/refresh', 'post', params)
  }

  checkUser = async () => {
    const response = await this.request<T.CheckUserResponse>('/check_user', 'get')

    // HACK: Stag and Itrl environments return a different api response. This is a temporary fix to handle the different responses.
    if ('message' in response) {
      return {
        access: { sme: response.message === 'success', app: true },
        isSponsor: false,
        isEditor: false,
        sponsorId: 'unknown-uuid',
        sponsorName: 'unknown-sponsor',
        userId: 'unknown-uuid',
      }
    }

    return response
  }

  /**
   * File Uploads
   */
  uploadPrepare = async (params: T.PrepareUploadParams) => {
    return this.request<T.PrepareUploadResponse>('/prepare-upload', 'get', params)
  }

  upload = async (params: T.UploadParams) => {
    return this.request(`/files/${params.file_path}`, 'put', params.file_content, {
      headers: { 'Content-Type': params.file_content.type },
    })
  }

  /**
   * Content Modules
   */
  getContentModules = async () => {
    return this.request<T.GetContentModulesResponse>('/cm/list', 'get')
  }

  getContentModule = async (params: T.GetContentModuleParams) => {
    return this.request<T.GetContentModuleResponse>(`/cm/${params.task_id}`, 'get')
  }

  createContentModule = async (params: T.CreateContentModuleParams) => {
    const response = await this.request<T.CreateContentModuleResponse>('/cm/create', 'post', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    // If there's no processing needed, return immediately
    if (!response.inProcess) {
      return response
    }

    await this.pollForProcessing({
      getStatusFn: () => this.getContent({ task_id: response.taskId }),
      logPrefix: 'createContentModule',
    })

    return response
  }

  updateContentModule = async (params: T.UpdateContentModuleParams) => {
    return this.request<T.UpdateContentModuleResponse>('/cm/update', 'post', params)
  }

  changeContentModuleStatus = async (params: T.ChangeContentModuleStatusParams) => {
    return this.request<T.ChangeContentModuleStatusResponse>(`/cm/change_status`, 'post', params)
  }

  /**
   * ESKs
   */
  getESKs = async () => {
    const data = await this.request<T.GetESKsResponse>('/cm/available', 'get')

    // HACK: Dev environment returns duplicate ESKs. This is a temporary fix to dedupe the array.
    const newData = data.filter((esk1, i, arr) => arr.findIndex(esk2 => esk2.eskId === esk1.eskId) === i)

    return newData
  }

  /**
   * Content
   */
  getContent = async (params: T.GetContentParams) => {
    return this.request<T.GetContentResponse>(`/content/get`, 'get', params)
  }

  updateContentText = async (params: T.UpdateContentTextParams) => {
    const response = await this.request<UpdateContentTextResponse>('/content/update_text', 'post', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    // If there's no processing needed, return immediately
    if (!response.inProcess) {
      return response
    }

    await this.pollForProcessing({
      getStatusFn: () => {
        if ('task_id' in params) {
          return this.getContent({ task_id: params.task_id! })
        } else if ('step_id' in params) {
          return this.getContent({ step_id: params.step_id })
        }
        throw new Error('updateContentText: cannot poll without task_id or step_id')
      },
      logPrefix: 'updateContentText',
    })

    return response
  }

  /**
   * Resources
   */
  getResources = async (params: T.GetResourcesParams) => {
    return this.request<T.GetResourcesResponse>('/cm/get_resources', 'get', params)
  }

  createResource = async (params: T.CreateResourceParams) => {
    const response = await this.request<T.CreateResourceResponse>('/cm/add_resource', 'post', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
      formSerializer: { indexes: null },
    })

    // If there's no processing needed, return immediately
    if (!response.inProcess) {
      return response
    }

    await this.pollForProcessing({
      getStatusFn: async () => {
        const resources = await this.getResources(
          params.task_id ? { task_id: params.task_id } : { step_id: params.step_id! },
        )
        const resource = resources.find(r => r.resourceId === response.resourceId)
        if (!resource) {
          throw new Error('createResource: resource not found in polling response')
        }
        return resource
      },
      logPrefix: 'createResource',
    })

    return response
  }

  updateResource = async (params: T.UpdateResourceParams) => {
    const response = await this.request<T.UpdateResourceResponse>('/cm/update_resource', 'post', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
      formSerializer: { indexes: null },
    })

    // If there's no processing needed, return immediately
    if (!response.inProcess) {
      return response
    }

    await this.pollForProcessing({
      getStatusFn: async () => {
        const resources = await this.getResources(
          params.task_id ? { task_id: params.task_id } : { step_id: params.step_id! },
        )
        const resource = resources.find(r => r.resourceId === params.resource_id)
        if (!resource) {
          throw new Error('updateResource: resource not found in polling response')
        }
        return resource
      },
      logPrefix: 'updateResource',
    })

    return response
  }

  deleteResource = async (params: T.DeleteResourceParams) => {
    return this.request<unknown>('/cm/delete_resource', 'post', params)
  }

  /**
   * FAQs
   */
  getFAQs = async (params: T.GetFAQsParams) => {
    return this.request<T.GetFAQsResponse>('/cm/get_faqs', 'get', params)
  }

  createFAQs = async (params: T.CreateFAQsParams) => {
    return this.request<unknown>('/cm/add_faqs', 'post', params)
  }

  updateFAQs = async (params: T.UpdateFAQsParams) => {
    return this.request<unknown>('/cm/update_faqs', 'post', params)
  }

  deleteFAQs = async (params: T.DeleteFAQsParams) => {
    return this.request<unknown>('/cm/delete_faqs', 'post', params)
  }

  /**
   * Steps
   */
  getSteps = async (params: T.GetStepsParams) => {
    return this.request<T.GetStepsResponse>('/step/task_steps', 'get', params)
  }

  createStep = async (params: T.CreateStepParams) => {
    const response = await this.request<T.CreateStepResponse>('/step/create', 'post', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    // If there's no processing needed, return immediately
    if (!response.inProcess) {
      return response
    }

    await this.pollForProcessing({
      getStatusFn: () => this.getContent({ step_id: response.stepId }),
      logPrefix: 'createStep',
    })

    return response
  }

  deleteStep = async (params: T.DeleteStepParams) => {
    return this.request<unknown>(`/step/delete?step_id=${params.step_id}&task_id=${params.task_id}`, 'delete')
  }

  updateStep = async (params: T.UpdateStepParams) => {
    return this.request<unknown>('/step/update', 'post', params)
  }

  /**
   * Generate
   */
  generateMetadata = async (params: T.GenerateMetadataParams) => {
    return this.request<T.GenerateMetadataResponse>('/cm/generate_metadata', 'post', params)
  }

  generateFAQs = async (params: T.GenerateFAQsParams) => {
    return this.request<T.GenerateFAQsResponse>('/cm/generate_questions', 'post', params)
  }

  generateDemographic = async (params: T.GenerateDemographicParams) => {
    return this.request<T.GenerateDemographicResponse>('/cm/generate_demographic', 'post', params)
  }

  generateStepObjectives = async (params: T.GenerateStepObjectivesParams) => {
    return this.request<T.GenerateStepObjectivesResponse>('/step/generate_objectives', 'post', params)
  }

  /**
   * Sponsor Topics
   */
  getSponsorTopics = async () => {
    return this.request<T.GetSponsorTopicsResponse>('/sponsor/cm_topics_by_user_id', 'get')
  }

  /**
   * Analytics
   */
  getLoginsPerSME = async (params: any) => {
    return await this.request<T.TimeSeriesResponse>('/analytics/logins_per_sme', 'get', params)
  }

  getCmEditsPerSME = async (params: any) => {
    return await this.request<T.TimeSeriesResponse>('/analytics/cm_edits_per_sme', 'get', params)
  }

  getEditsPerCm = async (params: any) => {
    return await this.request<T.HistogramResponse>('/analytics/edits_per_cm', 'get', params)
  }

  private async pollForProcessing<T>({
    getStatusFn,
    logPrefix,
  }: {
    getStatusFn: () => Promise<T>
    logPrefix: string
  }): Promise<T | null> {
    console.log(`${logPrefix}: start polling`)
    let pollResponse: T | null = null
    let timeout = 30 * 60 * 1000 // 30 minute timeout

    do {
      // wait for 5s between polling
      await new Promise(resolve => setTimeout(resolve, 5000))
      timeout -= 5000
      console.log(`${logPrefix}: poll`)

      pollResponse = await getStatusFn()

      if (timeout <= 0) {
        console.log(`${logPrefix}: polling timed out`)
        break
      }
    } while (this.isProcessing(pollResponse))

    console.log(`${logPrefix}: done polling`)
    return pollResponse
  }

  async updateTopicPriority(params: T.UpdateTopicPriorityParams): Promise<void> {
    // Mock implementation - just log the update
    console.log('Mock: Updating topic priority', params)
    return Promise.resolve()
  }

  private isProcessing(response: any): boolean {
    return response?.inProcess === true
  }
}
